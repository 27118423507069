import * as React from "react";
import "../styles/privacy-style.scss";
import Image from "../components/Image";
import Header from "./header";
import Collapsible from "react-collapsible";
import logo from "../images/logo.png";
const stars = {
  width: "100%",
  height: "100%",
  position: "absolute",
  zIndex: 0,
  objectFit: "cover",
};
const terms = [
  {
    heading: "NEKOMIMI KAT CLUB INTRO",
    des: `Welcome to the Nekomimi Kat club!
        Web Address: https://nekomimikatclub.io/.                                                  
        Nekomimi Kat club is a standalone entity created by NEKOMIMI KAT CLUB with no affiliation to
        others.
        
        This website is only an interface allowing participants to exchange electronic digital collectibles. All
        users are responsible for the safety and management of their own private wallets on the Ethereum
        blockchain. We are unable to reverse or restore transactions on this public network.
        
        This website and all connected services are provided as available without such warranty of any
        capacity. By accessing and/or using any part of this site and provided services/products, you agree
        that you have read and understand to be bound by these terms.
        
        If you do not agree to these terms and any applicable laws, please cease access or use of this site
        immediately.`,
  },
  {
    heading: "DEFINITIONS",
    des: `“NEKOMIMI” is the character and asset as part of the NEKOMIMI KAT CLUB NFT collection found
    on: https://opensea.io
    “OWN” is defined as possession of a NEKOMIMI NFT in your Ethereum wallet, where there is proof of
    possession on the public blockchain.`,
  },
  {
    heading: "OUR OWNERSHIP",
    des: `We own the name and intellectual property involved with “NEKOMIMI KAT CLUB ”, “NEKOMIMI &
    NEKOMIMI KAT CLUB Official” and as used on the blockchain and product outlets.
    
    All rights that are NOT specifically granted to the users and owners of NEKOMIMI tokens are
    reserved for the project. This includes items, such as: official logos, custom artwork, trademarks, the
    website, the aesthetics of the brand and character, the smart contract code and everything else not
    specifically granted by any of the licences.
    
    We reserve the rights to use your asset in any marketing materials. Any assets that are used in
    product placement will be reviewed prior to publishing and legally binded. Should you purchase a
    NFT in the legally binded state, you are agreeing to these terms in which you will take over the legal
    obligation.`,
  },
  {
    heading: "COMMERCIAL USE & DERIVATIVE WORK",
    des: `Any usage of NEKOMIMI KAT CLUB NFT licensed materials must be in FULL and in their entirety.
    
    Licensed use of the character and/or any custom artwork MUST include the complete image in its
    entirety, with the EXCEPTION of the background. The background MAY be removed.
    
    Individual expressions and traits are reserved for NEKOMIMI KAT CLUB and are prohibited to be
    used outside of the complete image. These individually layered files are proprietary works of
    NEKOMIMI KAT CLUB.
    
    Counterfeit NFTs, otherwise known as knock-offs of our art is prohibited and will be subject to DMCA
    by the law firm of NEKOMIMI KAT CLUB.
    
    NFT Knock-off collections created in an attempt to pass themselves off as genuine NEKOMIMI KAT
    CLUB creations will be reported and subject to DMCA.
    
    A participant of this experience must not use any NEKOMIMI KAT CLUB licensed materials in any
    way, or in connection with any organisations/material which is unlawful, fraudulent, offensive or
    otherwise objectionable. If the asset is found to be used incorrectly and against our terms and
    conditions, we are not responsible for any charges made against the holder. The holder shall not use
    NEKOMIMI KAT CLUB licensed materials in any way that could be misconstrued as being adverse or
    derogatory to the image of NEKOMIMI KAT CLUB or any of its subjects/partners.`,
  },
  {
    heading: "NOT AN INVESTMENT INSTRUMENT",
    des: `Assets in this collection and items used on this website are for fun and not meant to be viewed as
    investment vehicles.
    
    Any art that is provided to you by NEKOMIMI KAT CLUB is meant to function as fun, digital collectible
    assets. These NFTs are not to be used as a financial instrument. We cannot make any promises or
    guarantees regarding the value of our tokens. We also cannot guarantee that your NFT will be
    selected for licensing opportunities within any given timeline of the project.
    
    We cannot guarantee that any/all roadmap activations can be met and/or the time frame in which it is
    delivered.
    
    As part of this project, we offer giveaways and contests in the form of donations in good faith. We
    reserve the right to reduce or remove these offerings at any time.`,
  },
  {
    heading: "TAXES",
    des: `You are ENTIRELY responsible for any tax liabilities which may arise from minting, trading or owning
    NEKOMIMI KAT CLUB NFTs.`,
  },
  {
    heading: "GIVEAWAYS AND CONTESTS",
    des: `By holding a NEKOMIMI KAT CLUB NFT, you will have the opportunity to partake in future releases, such
    as airdrops, merch drops and more.
    
    Any giveaway prizes in physical form, should you opt out to provide a mailing address, we reserve the
    right to pay the equivalent in SOL instead.
    
    Execution and timing of any advertised utility/contest is subject to the given value of the Ethereum
    token. All activations are discretionary to NEKOMIMI KAT CLUB. Participating in such activities is
    subject to the rules and guidelines of each party/company involved.`,
  },
  {
    heading: "SUBJECT TO CHANGE",
    des: `Terms and conditions are revised from time to time and we reserve the rights to do so. You are
    responsible for reviewing this page at your own accord. By holding a NEKOMIMI KAT CLUB NFT,
    you agree to be bound by these terms.`,
  },
];
export const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <div className="img-container">
        <img src={logo} alt="" className="logo-img-privacy" />
      </div>

      <Image src="roadmap-bg.png" style={stars}></Image>
      <div className="privacy-content">
        <div
          className="subHeading"
          style={{
            lineHeight: "unset",
            textAlign: "center",
          }}
        >
          Terms & Conditions
        </div>
        {terms.map((term) => (
          <div className="question-container">
            <Collapsible trigger={term.heading}>
              <p>{term.des}</p>
            </Collapsible>
          </div>
        ))}
      </div>
    </div>
  );
};
export default PrivacyPolicy;
